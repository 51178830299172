import React, { Component } from "react";
import Logo from "../assets/img/logo.png";

export default class donateModal extends Component {
  render() {
    return (
      <div>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="pb-5 text-right">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="bg-white border-radius py-4 px-4">
                <div className="text-center font30 font-light ">
                  <img src={Logo} alt="" className="modal-logo" />
                </div>
                <div className="my-4 text-center bg-grey py-3">
                  <span className="font20">
                    Help protect the most vunulreble
                  </span>
                </div>
                <div className="my-3">
                  <label className="text-grey">Name on Card</label>
                  <input
                    type="text"
                    className="border form-control"
                    placeholder="John Doe"
                  />
                </div>
                <div className="my-3">
                  <label className="text-grey">Card Number</label>
                  <input
                    type="text"
                    className="border form-control"
                    placeholder="0000 0000 0000 0000"
                  />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <div className="my-3">
                      <label className="text-grey">Card Expiry</label>
                      <input
                        type="text"
                        className="border form-control"
                        placeholder="MM/YY"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="my-3">
                      <label className="text-grey">CVC</label>
                      <input
                        type="text"
                        className="border form-control"
                        placeholder="CVC"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button className="btn common-btn bg-blue">Donate</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
