import React, { Component } from "react";
import Navbar from "../components/navbar";
import DonateModal from "../components/donateModal";
import { Link } from "react-router-dom";
import Shelter from "../assets/img/shelter.svg";
import Food from "../assets/img/food.svg";
import Cloth from "../assets/img/clothes.svg";
import Help from "../assets/img/help.svg";
// import Carousel from "../components/carousel";

export default class home extends Component {
  render() {
    return (
      <div>
        <section id="head">
          <div className="home container">
            <Navbar />
            <header>
              <div className="row">
                <div className="col-lg-6 col-sm-12 text-white opacity">
                  <p className="font20 ">
                    Do all the good you can, by all the means you can, in all
                    the ways you can, in all the places you can, at all the
                    times you can, to all the people you can, as long as ever
                    you can.
                  </p>
                  <p className="font-bold"> - John Wesley</p>
                  <h3 className="font30 font-bold">We can all do good.</h3>

                  <a
                    href="https://gf.me/u/xwq8td"
                    alt="donate"
                    className="btn common-btn"
                    // data-toggle="modal"
                    // data-target="#donateModal"
                  >
                    Donate Now
                  </a>
                  <a href="#about">
                    <button className="btn common-btn-outline ml-3">
                      Read more
                    </button>
                  </a>
                </div>
              </div>
              {/* <!-- Modal --> */}
              <div
                class="modal fade"
                id="donateModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="donateModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <DonateModal></DonateModal>
                </div>
              </div>
            </header>
          </div>
        </section>

        <section className="overlay-card container">
          <div className="row">
            <div className="col-lg-3 col-6">
              <Link
                // to="/request/Shelter"
                to={{
                  pathname: `/request`,
                  search: "",
                  state: { selectedMenu: "Rent" },
                }}
              >
                <div className="rent need-card bg-grey">
                  <div>
                    <img src={Shelter} alt="Shelter" />
                  </div>
                  <p>Need Shelter/Rent Assistance?</p>
                  <span className="font12 py-1 border-radius bg-white bg-grey">
                    Contact Us
                  </span>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link
                to={{
                  pathname: `/request`,
                  search: "",
                  state: { selectedMenu: "Food" },
                }}
              >
                <div className="food need-card bg-white">
                  <div>
                    <img src={Food} alt="" />
                  </div>
                  <p>Need Food?</p>
                  <span className="font12 py-1 border-radius bg-grey">
                    Contact Us
                  </span>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link
                to={{
                  pathname: `/request`,
                  search: "",
                  state: { selectedMenu: "Clothes" },
                }}
              >
                <div className="clothing need-card bg-grey">
                  <div>
                    <img src={Cloth} alt="" />
                  </div>
                  <p>Need Clothing?</p>
                  <span className="font12 py-1 border-radius bg-white">
                    Contact Us
                  </span>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link
                to={{
                  pathname: `/request`,
                  search: "",
                  state: { selectedMenu: "Help" },
                }}
              >
                <div className="help need-card bg-white">
                  <div>
                    <img src={Help} alt="" />
                  </div>
                  <p>Need Help?</p>
                  <span className="font12 py-1 border-radius bg-grey">
                    Contact Us
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </section>

        <section className="container py-5 text-center" id="about">
          <h1 className="font-bold mt-5 mb-3">Creation of Benevolence</h1>
          <p className="font20">
            I can't pinpoint the actual day, year, or time Benevolence was
            founded. The farthest back I can place the desire is when I attended
            a service with a well known Bishop from California. During that
            service, which was held in a huge venue, the Bishop announced that
            he felt led to give the offering he received to people in need. The
            next thing I remember is people crying. People sitting beside me, in
            front of me, behind me; as far as I could see, were crying. I began
            to cry too. I wasn't in need. Maybe I was just in shock. I'd never
            seen or heard of anyone doing that before.
          </p>
          <p className="font20">
            What I have seen is people tithing faithfully who were unable to
            meet their own needs. I've heard of benevolence funds that people
            had to qualify for. I've heard of people being prayed for, and not
            helped. I've seen and I've heard. It saddened me. I stopped tithing
            to a building a long time ago. I've constantly sought places and
            persons to give to. I've found there are many in the body of Christ
            who have need. For a long time I kept trying to figure out how I
            could meet their need. Where could my tithe help people? Only a few
            months ago sitting with my best friend at Claim Jumpers did
            Benevolence come to me. Just recently, 3/31/20, to be exact did I
            purchase a logo and began my pursuit of figuring out what I could
            do. I lay in bed thinking about what is it that I can do. Right now.
            In this moment. With what I have.
          </p>
          <p className="font20">
            I decided that what I wanted was - to do good, for everybody. For
            anybody. Not just the Body. Just to do good.
          </p>
          <p className="font20 text-right mt-4">
            Kindly, <br />
            T.V.G.
          </p>
        </section>

        <section className="container py-5 text-center" id="more">
          <p className="font-bold">
            All money received will be dispersed to those who have needs. As
            money comes in, it will be given out.
          </p>
          <p className="font-bold">
            Please contact us if you have any questions concerning the use of
            your donation.
          </p>
        </section>
        {/* <section className="container my-5" id="testimonies">
          <div className="text-center py-5 font30 font-bold">
            Stories from Beneficiaries
          </div>
          <Carousel />
        </section> */}
      </div>
    );
  }
}
