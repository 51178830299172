import React, { Component } from "react";
import Navbar from "../components/navbar";
// import Carousel from "../components/carousel";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

let SUBJECT_ADMIN = "New Testimony";
let EXTRA_NOTE_ADMIN = "";
let ADMIN_EMAIL = "info@benevolence.care";

export default class testimonies extends Component {
  state = {
    fname: "",
    lname: "",
    isAnonymous: "",
    email: "",
    message: "",
    selectedMenu: "",
  };

  sendRequest = () => {
    // e.preventDefault();
    // this.notify("Hello");
    // return;

    const { fname, lname, isAnonymous, email, message } = this.state;

    if (!isAnonymous && !fname) {
      alert("First name is required");
      return;
    }

    if (!isAnonymous && !lname) {
      alert("Last name is required");
      return;
    }

    if (!isAnonymous && !email) {
      alert("Email is required");
      return;
    }

    if (!message) {
      alert("Message is required");
      return;
    }

    this.notify("Sending, Please wait..");

    axios({
      method: "POST",
      url: "https://us-central1-benevolence-b9aaa.cloudfunctions.net/sendMail",
      data: {
        name: isAnonymous ? "Anonymous" : fname + " " + lname,
        email: email ? email : "Anonymous",
        message,
        need: "na",
        phoneNo: "na",
        destination: ADMIN_EMAIL,
        subject: SUBJECT_ADMIN,
        extraNote: EXTRA_NOTE_ADMIN,
      },
    }).then((response) => {
      console.log("Email response: ", response);
      this.setState(
        {
          loading: false,
          fname: "",
          lname: "",
          isAnonymous: false,
          email: "",
          message: "",
        },
        () => {
          this.notify("Thank you. Your testimony has been sent successfully");
        }
      );
    });
  };
  notify = (message) => toast(message);

  render() {
    return (
      <div>
        <section className="bg-grey">
          <ToastContainer />

          <header>
            <div className="testimonies">
              <div className="container">
                <Navbar />
                <div className="row py-5">
                  <div className="col-lg-6 col-sm-12 text-white my-auto">
                    <h3 className="font64 font-bold text-white line-height">
                      Share your
                      <br /> Testimonies
                    </h3>
                    <p className="font18 mt-4">
                      Your story can inspire our donors.
                    </p>
                    <p className="font18">
                      Kindly enter your testimonies below, we will upload it
                      shortly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="container my-5">
            {/* <div className="py-5">
              <div className="text-center pb-5 font30 font-bold">
                Stories from Beneficiaries
              </div>
              <Carousel />
            </div> */}
            <div className="row">
              <div className="col-lg-3 col-sm-12"></div>
              <div className="col-lg-7 col-sm-12">
                <div className="bg-white border-radius p-4">
                  <div className="text-center">
                    <p className="font30">Your happiness gives us joy</p>
                  </div>

                  <div className="row my-4">
                    <div className="col-lg-6 col-sm-12">
                      <label className="font13"> First Name</label>
                      <input
                        type="text"
                        placeholder="Enter your first name"
                        className="form-control bg-grey common-input"
                        onChange={(e) => {
                          this.handleChange(e, "fname");
                        }}
                        value={this.state.fname}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 sm-mt">
                      <label className="font13"> Last Name</label>
                      <input
                        type="text"
                        placeholder="Enter your last name"
                        className="form-control bg-grey common-input"
                        onChange={(e) => {
                          this.handleChange(e, "lname");
                        }}
                        value={this.state.lname}
                      />
                    </div>
                  </div>

                  <div
                    className="row my-4 col-12"
                    onClick={() => {
                      this.setState({
                        isAnonymous: !this.state.isAnonymous,
                      });
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.isAnonymous}
                      onChange={(e) => {}}
                    />

                    <label className="font12 font-bold ml-3">
                      {" Send as anonymous"}
                    </label>
                  </div>
                  <div className="row my-4">
                    <div className="col-12">
                      <label className="font13"> Email Address*</label>
                      <input
                        type="text"
                        placeholder="Enter your email address"
                        className="form-control bg-grey common-input"
                        onChange={(e) => {
                          this.handleChange(e, "email");
                        }}
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-12">
                      <label className="font13">What did we do?</label>
                      <textarea
                        type="text"
                        className="form-control bg-grey common-input"
                        placeholder="Write to us what you would want us to know....."
                        onChange={(e) => {
                          this.handleChange(e, "message");
                        }}
                        value={this.state.message}
                      />
                    </div>
                  </div>
                  <div className="text-right">
                    <div
                      className="btn common-btn bg-blue"
                      onClick={() => {
                        this.sendRequest();
                      }}
                    >
                      Post
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  handleChange = (e, inputValue) => {
    this.setState({ [inputValue]: e.target.value });
  };
}
